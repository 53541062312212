import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import useLanguage from 'lib/hooks/useLanguage';

const I18nInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const lng = useLanguage();
  const [isInitialized, setIsInitialized] = useState(false);
  
  useEffect(() => {
    if (lng?.value) {
      i18n.changeLanguage(lng.value);
      setIsInitialized(true);
    } 
    else if (lng === null) {
      i18n.changeLanguage('en');
      setIsInitialized(true);
    }
  }, [lng]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isInitialized) {
        console.warn('Language API request timed out or failed, defaulting to English');
        i18n.changeLanguage('en');
        setIsInitialized(true);
      }
    }, 3000); // 3 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isInitialized]);

  if (!isInitialized) {
    return <span className="sr-only">Loading...</span>;
  }

  return <>{children}</>;
};

export default I18nInitializer;