import { api } from "lib/api";
import { iConfig } from "lib/api/Config";
import useSWR from "swr";

const useLanguage = () => {
    const apiFetchConfig = api.config().getBySlug('language-code');
    
    const { data: lng, error } = useSWR<iConfig | null>(
        apiFetchConfig.key,
        async () => {
            try {
                return await apiFetchConfig.fetcher();
            } catch (err) {
                console.warn("Failed to fetch language configuration:", err);
                return null;
            }
        },
        {
            shouldRetryOnError: false,
            dedupingInterval: 1000,
        }
    );

    if (error || lng === null) {
        return null;
    }

    return lng;
};

export default useLanguage;