import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { getAuth } from "./auth";

const { REACT_APP_API_BASE_URL, REACT_APP_MOCKAPI_BASE_URL } = process.env;

export interface iEnmarcoWrapper<Data = any> {
  success: boolean;
  data: Data;
}

// Create a custom axios instance with default settings
const axiosInstance: AxiosInstance = axios.create({
  timeout: 10000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  maxRedirects: 0, // Prevent redirects which cause CORS issues with preflight
  withCredentials: false
});

export default async function fetch<Data = any>(
  path: string,
  data?: object,
  options: AxiosRequestConfig = {}
): Promise<Data> {
  const baseUrl = !path.startsWith("~")
    ? REACT_APP_API_BASE_URL
    : REACT_APP_MOCKAPI_BASE_URL;
    
  // Ensure the path starts with "/" if it doesn't already
  const formattedPath = path.startsWith('/') || path.startsWith('~') ? path : `/${path}`;
  
  const r = await axiosInstance({
    method: data === undefined ? "get" : "post",
    ...options,
    headers: {
      Authorization: `Bearer ${getAuth()}`,
      ...options?.headers,
    },
    // remove ~ for fake api
    url: `${baseUrl}${formattedPath.replace(/^~/, "")}`,
    data,
  });
  return r.data;
}
