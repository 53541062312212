import EntityCRUD, { iBaseEntityWithName } from "./EntityCRUD";

export interface iPaperType extends iBaseEntityWithName {
}

export default class PaperType extends EntityCRUD<iPaperType> {
  static basePath = "/paper-types";
}


