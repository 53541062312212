import EntityCRUD from "./EntityCRUD";

export interface iSuscriptionPlan {
  id: string;
  number: number;
  name: string;
  fixedFee: number;
  variableFee: number;
  maxOrders: number;
  maxMoldings: number;
  buyingProcesses: number;
  maxUsers: number;
  createOrders: boolean;
  addProducts: boolean;
  coupons: boolean;
  productLinks: boolean;
  featuredProducts: boolean;
}

export interface iSuscriptionPlanModel {
  id: string;
  number: number;
  name: string;
  fixed_fee: number;
  variable_fee: number;
  max_orders: number;
  max_moldings: number;
  buying_processes: number;
  max_users: number;
  create_orders: boolean;
  add_products: boolean;
  coupons: boolean;
  product_links: boolean;
  featured_products: boolean;
};

export default class SuscriptionPlan extends EntityCRUD<iSuscriptionPlan> {
  static basePath = "/suscription-plan";

  static adapter(data: iSuscriptionPlan | iSuscriptionPlanModel) {
    if ((data as iSuscriptionPlanModel).buying_processes !== undefined) {
      const model = data as iSuscriptionPlanModel;
      return {
        ...model,
        fixedFee: model.fixed_fee,
        variableFee: model.variable_fee,
        maxOrders: model.max_orders,
        maxMoldings: model.max_moldings,
        buyingProcesses: model.buying_processes,
        maxUsers: model.max_users,
        createOrders: model.create_orders,
        addProducts: model.add_products,
        productLinks: model.product_links,
        featuredProducts: model.featured_products,
      };
    } else {
      const item = data as iSuscriptionPlan;
      return {
        ...item,
        fixed_fee: item.fixedFee, 
        variable_fee: item.variableFee,
        max_orders: item.maxOrders,
        max_moldings: item.maxMoldings,
        buying_processes: item.buyingProcesses,
        max_users: item.maxUsers,
        create_orders: item.createOrders,
        add_products: item.addProducts,
        product_links: item.productLinks,
        featured_products: item.featuredProducts,
      };
    }
  }
}
